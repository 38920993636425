<template>
    <div class="register-container register text-center animated flipInX">
      <div class="container-content">
        <transition name="slide-fade" appear>
          <form @submit.prevent="handleSubmit">
            <img src="@/assets/empresa.svg" alt="User" class="img-login">
            <h5 class="text-whitesmoke">PORTAL DE PROVEEDORES</h5>
            <p class="text-whitesmoke">Registrar Proveedor</p>
            <div class="form-group" :class="{error: validation.hasError('email')}">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text"><font-awesome-icon :icon="{ prefix: 'fas', iconName: 'envelope', }"/></span>
                </div>
                <input type="text" class="form-control" placeholder="Email" v-model="email">
                <span class="icon" :title="validation.firstError('email')" v-if="validation.hasError('email')">
                  <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'exclamation-circle' }" class="icon"/>
                </span>
              </div>
            </div>
            <div class="message" v-if="validation.hasError('email')">{{ validation.firstError('email') }}</div>

            <div class="form-group" :class="{error: validation.hasError('password')}">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text"><font-awesome-icon :icon="{ prefix: 'fas', iconName: 'lock', }"/></span>
                </div>
                <!-- <span class="icon" :title="validation.firstError('password')" v-if="validation.hasError('password')">
                  <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'exclamation-circle' }" class="icon"/>
                </span> -->
                <input v-if="showPassword" type="text" class="form-control" placeholder="Contraseña" v-model="password">
                <input v-else type="password" class="form-control" placeholder="Contraseña" v-model="password">
                <div class="input-group-append cursor-pointer">
                    <span class="input-group-text">
                        <div @click="toggleShow">
                            <!-- <i class="fas" :class="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }"></i> -->
                            <font-awesome-icon :icon="{ prefix: 'fa', iconName: showPassword ? 'eye-slash' : 'eye' }" class="no-help"/>
                        </div>
                    </span>
                </div>
              </div>
            </div>
            <div class="message" v-if="validation.hasError('password')">{{ validation.firstError('password') }}</div>

            <div class="form-group" :class="{error: validation.hasError('razon_social')}">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text"><font-awesome-icon :icon="{ prefix: 'fas', iconName: 'building', }"/></span>
                </div>
                <input type="text" class="form-control" placeholder="Razon Social" v-model="razon_social">
                <span class="icon" :title="validation.firstError('razon_social')" v-if="validation.hasError('razon_social')">
                  <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'exclamation-circle' }" class="icon"/>
                </span>
              </div>
            </div>
            <div class="form-group" :class="{error: validation.hasError('rfc')}">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text"><font-awesome-icon :icon="{ prefix: 'fas', iconName: 'id-card', }"/></span>
                </div>
                <input type="text" class="form-control" placeholder="RFC" v-model="rfc">
                <span class="icon" :title="validation.firstError('rfc')" v-if="validation.hasError('rfc')">
                  <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'exclamation-circle' }" class="icon"/>
                </span>
              </div>
            </div>
            <div class="message" v-if="validation.hasError('rfc')">**{{ validation.firstError('rfc') }}</div>

            <div class="form-group" :class="{error: validation.hasError('registro_patronal')}">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text"><font-awesome-icon :icon="{ prefix: 'fas', iconName: 'id-card', }"/></span>
                </div>
                <input type="text" class="form-control" placeholder="Registro Patronal (solo subcontratistas)" v-model="registro_patronal">
                <span class="icon" :title="validation.firstError('registro_patronal')" v-if="validation.hasError('registro_patronal')">
                  <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'exclamation-circle' }" class="icon"/>
                </span>
              </div>
            </div>
            <div class="form-group" :class="{error: validation.hasError('rep_legal')}">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text"><font-awesome-icon :icon="{ prefix: 'fas', iconName: 'user-tie', }"/></span>
                </div>
                <input type="text" class="form-control" placeholder="Representante Legal" v-model="rep_legal">
                <span class="icon" :title="validation.firstError('rep_legal')" v-if="validation.hasError('rep_legal')">
                  <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'exclamation-circle' }" class="icon"/>
                </span>
              </div>
            </div>
            <div class="form-group" :class="{error: validation.hasError('contacto')}">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text"><font-awesome-icon :icon="{ prefix: 'fas', iconName: 'user', }"/></span>
                </div>
                <input type="text" class="form-control" placeholder="Nombre de Contacto" v-model="contacto">
                <span class="icon" :title="validation.firstError('contacto')" v-if="validation.hasError('contacto')">
                  <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'exclamation-circle' }" class="icon"/>
                </span>
              </div>
            </div>
            <button type="submit" class="form-button btn-block mb-2">
              <font-awesome-icon v-if="loading" :icon="{ prefix: 'fas', iconName: 'spinner', }" class="icon btn-icon" spin/>Crear cuenta
            </button>
            <router-link class="text-gray" :to="'/auth/login'">
              <small><font-awesome-icon :icon="{ prefix: 'fa', iconName: 'arrow-left', }"/> VOLVER A INICIO DE SESIÓN</small>
            </router-link>
          </form>
        </transition>
      </div>
    </div>
</template>

<script>
import SimpleVueValidation from 'simple-vue-validator';

const { Validator } = SimpleVueValidation;

export default {
  name: 'login',
  computed: {
    loading() {
      return this.$store.state.user.loading;
    },
  },
  data() {
    return {
      email: '',
      password: '',
      razon_social: '',
      rfc: '',
      rep_legal: '',
      contacto: '',
      registro_patronal: '',
      showPassword: false,
    };
  },
  validators: {
    email(value) { return Validator.value(value).required('Campo requerido').email('Formato de correo no válido').lengthBetween(5, 100, 'Longitud debe ser entre 5 y 6 caracteres'); },
    password: {
      cache: true,
      validator(value) {
        // eslint-disable-next-line consistent-return
        return Validator.value(value).minLength(6, '❌Se requieren al menos 6 caracteres').custom(() => {
          if (!Validator.isEmpty(value)) {
            return this.$delay(1000)
              // eslint-disable-next-line consistent-return
              .then(() => {
                const hasLower = value.toUpperCase() !== value;
                const hasDigit = ((/\d/).test(value));
                const hasSymbol = ((/[`!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?~]/).test(value));
                const error = ['✅Al menos 1 letra minúscula.', '✅Al menos 1 número.', '✅Al menos 1 símbolo.'];
                if (!hasLower) {
                  error[0] = '⚠️Al menos 1 letra minúscula';
                }
                if (!hasDigit) {
                  error[1] = '⚠️Al menos 1 número.';
                }
                if (!hasSymbol) {
                  error[2] = '⚠️Al menos 1 símbolo.';
                }
                if (!hasLower || !hasDigit || !hasSymbol) {
                  return error.join(' ');
                }
              });
          }
        });
      },
    },
    razon_social(value) { return Validator.value(value).required('Campo requerido').maxLength(250, 'Solo se permiten 250 caracteres'); },
    rfc(value) { return Validator.value(value).required('Campo requerido').lengthBetween(12, 13, 'Se requieren al menos 12 o 13 caracteres'); },
    rep_legal(value) { return Validator.value(value).required('Campo requerido').maxLength(150, 'Solo se permiten 150 caracteres'); },
    contacto(value) { return Validator.value(value).required('Campo requerido').maxLength(150, 'Solo se permiten 150 caracteres'); },
    // registro_patronal(value) { return Validator.value(value).lengthBetween(11, 12, 'Solo se permiten 11 o 12 caracteres'); },
    registro_patronal: {
      cache: true,
      debounce: 200,
      validator(value) {
        // eslint-disable-next-line consistent-return
        return Validator.value(value).lengthBetween(0, 12, 'Solo se permiten 11 o 12 caracteres').custom(() => {
          if (!Validator.isEmpty(value)) {
            return this.$delay(1000)
              // eslint-disable-next-line consistent-return
              .then(() => {
                if (value.length < 11 || value.length > 12) {
                  return 'Solo se permiten 11 o 12 caracteres';
                }
              });
          }
        });
      },
    },
  },
  mounted() {
    this.email = this.$route.query?.email ? this.$route.query?.email : '';
  },
  methods: {
    handleSubmit() {
      this.$validate().then((success) => {
        if (success) {
          this.$store.dispatch('user/REGISTER', {
            payload: {
              email: this.email,
              password: this.password,
              razon_social: this.razon_social,
              rfc: this.rfc,
              rep_legal: this.rep_legal,
              nombre: this.contacto,
              registro_patronal: this.registro_patronal,
            },
          });
        }
      });
    },
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
  },
  watch: {
    rfc() {
      this.$nextTick(() => {
        this.rfc = this.rfc.replace(/\s+/g, '');
      });
    },
  },
};
</script>

<style scoped lang="scss">
</style>
